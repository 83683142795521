import * as types from '../actions/actionTypes'
import authCookie from '../lib/authCookie';
import LoggerUtils from '../lib/loggerUtils';

const initialState = {
	isAuthenticated: false,
	token: '',
	expiresAt: null,
	email: '',
	userId: '',
	role: '',
	productInstanceId: 0,
	referralSystemId: 0,
	referralSiteId: 0,
	firstName: '',
	lastName: '',
	isTermsOfUseAccepted: false,
	permissions: [],
	redirectToUrl: '',
	applicationInsightsKey: '',
	debugParam: false,
	...authCookie.get(),
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.CLEAR_AUTH_TOKEN:
			return {
				...state,
				token: action.payload.token,
				productInstanceId: action.payload.productInstanceId,
				referralSiteId: action.payload.referralSiteId,
			};
		case types.SET_AUTH_TOKEN:
			return {
				...state,
				token: action.payload.token,
				productInstanceId: action.payload.productInstanceId,
				referralSiteId: action.payload.referralSiteId,
			};
		case types.PROCESS_PRODUCT_INSTANCE_SUCCESS:
			return {
				...state,
				productInstanceId: action.payload.data.config.id,
				applicationInsightsKey: action.payload.data.config.applicationInsightsKey,
			}
		case types.EXCHANGE_TOKEN_SUCCESS:
		case types.AUTHENTICATE_USER_SUCCESS:
			if (!action.payload.data.config.branding) {
				LoggerUtils.logError(`Auth Reducer auth success action`, action);
			}
			return {
				...state,
				productInstanceId: action.payload.data.config.id,
				isAuthenticated: true,
				token: action.payload.data.token,
				expiresAt: new Date(action.payload.data.expiresAt),
				email: action.payload.data.email,
				userId: action.payload.data.userId,
				role: action.payload.data.role,
				referralSystemId: action.payload.data.referralSystemId,
				referralSiteId: action.payload.data.referralSiteId,
				firstName: action.payload.data.firstName,
				lastName: action.payload.data.lastName,
				isTermsOfUseAccepted: action.payload.data.isTermsOfUseAccepted,
				compelPasswordChange: action.payload.data.compelPasswordChange,
				permissions: action.payload.data.permissions,
				redirectToUrl: action.payload.data.redirectToUrl,
			}
		case types.FIRST_LOGIN_SAVE_SUCCESS:
			return {
				...state,
				isTermsOfUseAccepted: action.payload.data.isTermsOfUseAccepted,
				compelPasswordChange: action.payload.data.compelPasswordChange,
			}
		case types.VERIFY_TOKEN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				token: action.payload.data.token,
				expiresAt: new Date(action.payload.data.expiresAt),
				email: action.payload.data.email,
				userId: action.payload.data.userId,
				role: action.payload.data.role,
				referralSystemId: action.payload.data.referralSystemId,
				referralSiteId: action.payload.data.referralSiteId,
				firstName: action.payload.data.firstName,
				lastName: action.payload.data.lastName,
				isTermsOfUseAccepted: action.payload.data.isTermsOfUseAccepted,
				compelPasswordChange: action.payload.data.compelPasswordChange,
				permissions: action.payload.data.permissions,
			}
			case types.SET_GUIDED_RESPONSE_DEBUG_CONTEXT:
			return {
				...state,
				debugParam: action.contextCriteria.debug
			};
		case types.KEEP_SESSION_ALIVE_SUCCESS:
			return {
				...state
			}
		default:
			return state
	}
}
